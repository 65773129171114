<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
<!--                    <th>Status</th>-->
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in businesses" :key="i">
                    <td> {{ item.name }}</td>
<!--                    <td> {{ item.status }}</td>-->
                    <td @click="goToEdit(item.id)">
                        <span style="cursor: pointer">View / Edit</span>
                    </td>
                </tr>
                
                </tbody>
            </table>
            <p v-if="!businesses.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import figureFormatter from '@/services/utils/figureFormatter'

export default {
    name: 'businessListTable',
    props: {
        businesses: []
    },
    setup() {
        const {commaFormat} = figureFormatter()
        
        return {
            commaFormat
        }
    },
    methods: {
        goToEdit(id) {
            this.$router.push({
                name: 'EditBusiness',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    businessId: id
                },
            })
        }
    }
}
</script>
